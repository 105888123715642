.delete {
    padding: 10px;
    color: #A51E36;
}

.edit {
    padding: 10px;
    color: #A51E36;
}

.deactivate {
    padding: 10px;
    color: #A51E36;
}

.activate {
    padding: 10px;
    color: #A51E36;
}

.actions {
    color: #A51E36;
}

.actions-status {
    color: #22af47;
}
