@import '~bootstrap/scss/bootstrap.scss';
@import 'style.scss';
@import 'icoicon/icons';
@import 'font-awesome.scss';
@import 'tippy.scss';

// GLOBAL CSS

// disable toggles on number fields
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance:textfield;
}

.sticky-table-header {
    overflow-y: auto;
    height: 800px;
}

.sticky-table-header thead th {
    position: sticky;
    top: 0;
}

.sticky-table-header thead th {
    background-color: white;
}

.hidden {
    display: none;
}

.m-signature-pad--body canvas{
    border: 1px solid #e8e1e1 !important;
}

.bold {
    font-weight: bold;
}
.bold-red {
    font-weight: bold;
    color: #A51E36 !important;
}
.bold-blue {
    font-weight: bold;
    color: #008cba !important;
}

.disabled {
    pointer-events: all !important;
}

.column-width {
    width: 450px;
}

.marginTop {
    margin-top: 10px;
}

.marginTopSearchable {
    margin-top: 28px;
}

$breakpoints: (
    "sm": 640px,
    "md": 768px,
    "lg": 1024px,
    "xl": 1280px,
    "2xl": 1536px,
);

@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    } @else {
        @warn "Unknown breakpoint: #{$breakpoint}.";
    }
}

.s-icon-lg {
    display: none;

    @include respond-to(lg) {
        display: block;
    }
}

.s-icon-sm {
    display: block;

    @include respond-to(lg) {
        display: none;
    }
}

.s-daily-report-summary,
.s-driller-production,
.s-operations-supervisor {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 16px 0 16px;

    @include respond-to(xl) {
        padding: 0;
    }

    .s-filters-form {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .s-filters {
            display: grid;
            grid-template-columns: auto;
            gap: 12px;
    
            @include respond-to(sm) {
                grid-template-columns: auto auto;
            }
    
            @include respond-to(md) {
                grid-template-columns: auto auto auto;
            }
    
            @include respond-to(lg) {
                grid-template-columns: auto auto auto auto;
            }
    
            @include respond-to(xl) {
                display: flex;
            }
    
            .s-justify-end {
                align-items: start;
                justify-content: flex-end;
            }
    
            .s-justify-between {
                align-items: start;
                justify-content: space-between;
            }
    
            .s-buttons {
                display: flex;
                margin-top: 32px;
    
                @include respond-to(xl) {
                    display: none;
                }
    
                .s-button {
                    button {
                        margin-top: 0px!important;
                    }
                }
            }
        }

        .s-button-xl {
            display: none;

            @include respond-to(xl) {
                display: block;
            }
        }
    }
}

.s-table-scrollable {
    width: 100%;
    overflow: auto;

    @include respond-to(md) {
        width: fit-content;
    }
}

.s-table-standard-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    .s-table-standard {
        width: 100%;
        overflow: auto;
    }
}

.s-table-scrollable-global-filter {
    border: 1px solid #eff0f1;
    padding: 10px 10px 10px 10px;
    border-radius: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    @include respond-to(sm) {
        width: 50%;
    }

    @include respond-to(md) {
        width: 30%;
    }
    
    .s-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        color: gray;
    }

    .s-divider {
        width: 1px;
        height: 24px;
        background-color: lightgray;
    }

    input {
        flex: 1;
        padding: 0;
    }
}

.s-table-standard-global-filter {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;

    @include respond-to(sm) {
        width: 50%;
    }

    @include respond-to(md) {
        width: 30%;
    }
}

.s-media-body {
    display: flex;
    align-items: center;
    gap: 4px;
}

.s-gy {
    grid-row-gap: 0.75rem;
}

.s-flex {
    display: flex;
}

.s-flex-col {
    flex-direction: column;
}

.s-justify-between {
    justify-content: space-between;
}

.s-items-center {
    align-items: center;
}

.s-flex-gy {
    gap: 24px;
}

.s-flex-gx {
    gap: 6px;
}

.s-color-error {
    color: red;
}

.s-w-full {
    width: 100%;
}

.s-menu-font-size {
    font-size: 18px!important;

    @include respond-to('md') {
        font-size: 16px!important;
    }

    @include respond-to('lg') {
        font-size: 14px!important;
    }
}

.s-search-dropdown {
    top: 300%;
    width: 300px;

    @include respond-to('sm') {
        top: 120%;
        width: 100%;
    }
}
