.cancel-button {
    margin-left: 10px;
}

.remove-all-button {
    margin-left: 45px;
}

.all-charges-button {
    margin-right: 80px !important;
}

