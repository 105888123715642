/**=====================
    25. Authentication CSS Start
==========================**/
//urls

$url_0: url(../../images/other-images/auth-layer.png);
$url_1: url(../../images/Background_Login/background.jpg);

%auth-mixin1 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.authentication-main {
  background: $light-color;
  padding: 15px 100px;
  .auth-innerright {
    @extend %auth-mixin1;
  }
}
.authentication-box {
  width: 460px;
  z-index: 2;
  margin: 0 auto;
  .theme-form {
    padding-top: 20px;
    .form-group {
      .col-form-label {
        color: $light-text;
      }
    }
    .social {
      .form-row {
        justify-content: center;
      }
    }
  }
  h3 {
    color: $dark-color;
    font-weight: 600;
    letter-spacing: 1px;
  }
  h4 {
    color: $theme-body-font-color;
    font-weight: 600;
    letter-spacing: 1px;
  }
  h6 {
    color: $theme-body-sub-title-color;
    letter-spacing: 1px;
    font-size: 14px;
  }
}

.main-container {
    position: relative;
    margin: 0 auto;
}

.highlight-white {
    color: #FFFFFF !important;
}

.email-icon {
    padding-top: 7px;
    padding-right: 2px;
    color: white;
}

.password-icon {
    padding-top: 1px;
    padding-right: 2px;
    color: white;
}

.whitelabel-logo-login {
    width: 107px;
    height: 88px;
    margin-top: 21px;
}

.drill-sight-wrapper {
    margin-bottom: 30px;
}

//.drill_sight_logo {
//    color: white !important;
//    font-size: 50px;
//    margin-top: 34px;
//    margin-right: 123px;
//    font-family: 'Marcellus', serif;
//}
.login-form {
    padding: 10px !important;
}

.remember-icon {
    color: white;
}

.remember-checkbox {
    padding-left: 2px !important;
}

.drill-button {
    background-color: Transparent;
    background-repeat:no-repeat;
    border: solid white;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    width: 24%;
    color: white;
    margin-left: 310px;
}

.input-field {
    background-color:rgba(0,0,0,0) !important;
    border-top:none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0px !important;
    width: 90% !important;
    color: white !important;
}

.main-container .login-form {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0); /* Black background with 0.5 opacity */
    width: 100%;
    padding: 20px;
}

.checkbox .login-remember-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 16px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: black !important;
}

.background {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.background-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 540px;
}

@media (min-width: 650px ) {
    .background {
        background-position: center center;
    }

    .background-inner {
        align-items: flex-end;
        justify-content: center;
    }
}

.auth-bg {
    background: $url_1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 50px 100px;
}

.auth-bg {
  background: $url_1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 100px;
}
.auth-bg-video {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.31);
  padding: 50px 15px;
  video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    transition: 1s opacity;
  }
}
.reset-password-box {
  width: 645px;
  z-index: 2;
  .card {
    padding: 30px;
  }
  .theme-form {
    .form-group {
      label {
        font-size: 12px;
        color: $theme-body-sub-title-color;
      }
      .form-control {
        font-size: 18px;
      }
      .btn {
        font-size: 18px;
        padding: 6px 24px;
      }
    }
    .opt-box {
      background: $light-body-bg-color;
      .opt-text {
        font-size: 36px;
        padding: 0;
      }
    }
  }
  .reset-password-link {
    color: $theme-body-sub-title-color;
  }
  .reset-password-box h6 {
    letter-spacing: 1px;
    font-size: 16px;
  }
}
@keyframes rotate-effect {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.s-login-page {
	background-image: url(../../images/Background_Login/background.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.s-login-container {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 48px;

		.s-form {
			display: flex;
			flex-direction: column;
			gap: 24px;

			.s-form-group {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 4px;

				input {
					flex: 1;
				}
			}

			.s-form-submit {
				display: flex;
				justify-content: right;

				.s-drill-button {
					background-color: Transparent;
					background-repeat: no-repeat;
					border: solid white;
					cursor: pointer;
					overflow: hidden;
					outline: none;
					color: white;
				}
			}
		}
	}
}

/**=====================
    25. Authentication CSS Ends
==========================**/





