/**=====================
     79. Print CSS Start
     Css that apply only in print view
==========================**/
@media print {
  .page-sidebar {
    display: none;
  }
  .page-wrapper {
    .page-main-header {
      visibility: hidden;
    }
    .page-body-wrapper {
      //.page-header {
      //  display: none;
      //}
      .page-sidebar ~ .page-body {
        margin-left: 0;
        padding: 0;
      }
    }
  }
  table.fixedHeader-floating {
    display: none;
  }
  .page-wrapper .page-body-wrapper .page-sidebar{
      display: none;
  }
  .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body{
    margin-left: 0;
  }
}

/**=====================
    79. Print CSS Ends
==========================**/
