.hours-column-width {
  width: 90px;
}
.column-width-75 {
  width: 75px;
}

.price-column-width {
  width: 120px;
}

.markup-price-column-width {
  width: 94px;
}

.name-column-width {
  width: 250px;
}

.short-select-width {
  width: 100px;
}

.phone-width {
  width: 160px;
}

.email-width {
  width: 300px;
}

.title-width {
  width: 200px;
}

.width-150 {
  width: 150px;
}

.notes-column-width {
  width: 200px;
}
