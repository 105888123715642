/**=====================
     39. Table  CSS Start
==========================**/
.pills-component {
	width: 20%;
}
.product-page-width {
	width: 80%;
}
.table{
	margin-bottom: $table-b-margin;
	th,td{
		padding: $table-padding;
	}
	th{
		color: $table-heading-color;
	}
	td{
		color: $table-row-color;
	}
	[class*='bg-']{
		color: $white;
	}
	.bg-light{
		color: $theme-body-font-color;
	}
	tfoot{
		font-weight: $table-footer-font-weight;
	}
}
.table-inverse {
	th,td{
		color: $white;
	}
}
.table[class*='bg-']{
	th,td{
		color: $white;
	}
}
.table-hover {
	tbody {
		tr:hover {
			background-color: $table-hover-color;
		}
	}
}

.action-cell {
	width: 130px;
	min-width: 130px;
	text-align: right;
}

//Sizing class
.table-xl{
	td,th{
		padding: $table-xl-padding;
		font-size: $table-xl-font;
	}
}
.table-lg{
	td,th{
		padding: $table-lg-padding;
		font-size: $table-lg-font;
	}
}
.table-de{
	td,th{
		padding: $table-de-padding;
	}
}
.table-sm{
	th,td{
		padding: $table-sm-padding;
		font-size: $table-sm-font;
	}
}
.table-xs{
	th,td{
		padding: $table-xs-padding;
		font-size: $table-xs-font;
	}
}

//Table Border
.table-border-horizontal {
	tr,th,td{
		border-top: 1px solid $horizontal-border-color;
		padding: $horizontal-padding;
	}
}
.table-border-vertical{
	tr,th,td{
		border-top: 0;
		border-right: 1px solid $horizontal-border-color;
	}
	tr{
		td{
			&:last-child{
				border-right: none;
			}
		}
	}
}

.table-bordernone{
	td{
		border: 0;
	}
	.bd-t-none{
		border-top: none;
	}
	.u-s-tb{
		padding: 11px;
	}
}
.table-inverse{
	color: $white;
	background-color: $table-inverse-bg-color;
	th,td{
		border-color: $white;
	}
	&.table-bordered {
		border: 0;
	}
}

.table-striped{
	tbody{
		tr{
			&:nth-of-type(odd){
				background-color: rgba(245, 247, 250, 0.5);
			}
		}
	}
}
.table-bordered {
	td, th {
		border-color: $light;
	}
}

.table-header-primary-color{
	thead{
		tr{
			background-color: $primary-color;
			text-align: center;
			th {
				color: $white;
			}
		}
	}
}

.table-label{
	background-color: rgba(245, 247, 250, 0.5);
	font-weight: bold;
};

.table-sorted-column{
	color: $primary-color;
	cursor: pointer;
}

.s-table-mobile-container {
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 12px 0 12px 0;

	.s-sorters {
		display: flex;
		gap: 8px;

		.s-sorter {
			width: 50%;
		}
	}

	.s-table-mobile {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.s-divider {
			background-color: lightgray;
			height: 1px;
			width: 100%;
		}

		.s-item {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.s-col-group {
				display: flex;
				flex-direction: column;
				gap: 8px;

				& > span:first-child {
					font-size: 16px;
					font-weight: 700;
				}
			}

			.s-row {
				display: flex;
				gap: 8px;

				span {
					font-size: 16px;
				}

				span:first-child {
					width: 33%;
				}

				span:second-child {
					flex: 1;
				}
			}
		}
	}
}

/**=====================
     39.Table CSS Ends
==========================**/
