.delete {
    padding: 10px;
    color: #A51E36;
}

.edit {
    padding: 10px;
    color: #A51E36;
}

.white {
    color: #ffffff;
}

.red {
    color: #ff5370;
}

.button-with-offset {
    margin-top: 30px;
}

.marginLeft {
    margin-left: 20px;
}

.marginBottom30 {
    margin-bottom: 30px;
}

.deactivate {
    padding: 10px;
    color: #A51E36;
}
