@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';

.shift_detail_tooltip.loading {
    width: initial;
}

.shift_detail_tooltip {
    width: 800px;
}
